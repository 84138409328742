




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { router } from '../main'; 
import axios from 'axios';
import * as settings from '../../../../common/src/settings';
import { SetSpotifyCodeDto } from '../../../../server/src/interfaces';
import store from '../store';

const apiAxios = axios.create();

@Component
export default class Callback extends Vue {
  async mounted() {
    const uri = window.location.search.substring(1); 
    const params = new URLSearchParams(uri);
    const code = params.get("code");

    store.commit('setPlayerName', params.get("state"));

    const body: SetSpotifyCodeDto = {
      userId: params.get("state")!,
      spotifyCode: code!
    }

    const tokens = await apiAxios.post(settings.urlprefix+"/setSpotifyCode", body);
    await this.$store.dispatch('loadDevices');
//    store.commit("setSpotifyAccessToken", tokens.data.access_token); // TODO - remove this one, replace with dispatch update user profile
//    await this.$store.dispatch('loadDevices');
    router.push('/');
  }
  
};
